<template>
  <div class="row">
    <div class="col-12">
      <trip-history trip-type="completed" :request-function="(params) => fetchTrips(params)" :show-actions="false"></trip-history>
    </div>
  </div>
</template>

<script setup>
import TripHistory from '@/components/modules/trips/TripHistory'
import { defineProps } from 'vue'
import { axiosInstance } from '@/plugins/axios'
const props = defineProps({
  vehicleId: {
    required: true,
    type: [Number, String]
  }
})
const fetchTrips = async (params) =>
{
  const limit = params ? params.limit : 10
  const page = params ? params.page : 1
  const search = params ? params.search : ''
  const url = `/v1/vehicles/${props.vehicleId}/rides?limit=${limit}&page=${page}&search=${search}&metadata=true`
  const result = await axiosInstance.get(url)

  const data = (result.data?.data || []).map((obj) =>
  {
    obj.trip_start_time = obj.start_trip
    return obj
  })

  return {
    data: data,
    count: result.data.metadata.total
  }
}
</script>

<style scoped></style>
